import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/common/jwt.service';
import auth from '@/app/auth/auth.helpers';
import ConfigService from './config.service';

const ApiService = {
    //see https://medium.com/@zitko/structuring-a-vue-project-authentication-87032e5bfe16
    _401interceptor: null,
    apiUrl() {
        let key = 'backendBaseUrl';
        return ConfigService.getConfigIfExists(key);
    },

    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = this.apiUrl();

        Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        Vue.axios.defaults.headers.common['Pragma'] = 'no-cache';
        Vue.axios.defaults.headers.common['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';

        if (JwtService.getToken() != null) {
            this.setJwtHeader();
            this.mount401Interceptor();
            this.setMandantHeader();
        }
    },

    setJwtHeader() {
        Vue.axios.defaults.headers.common['Authorization'] = `${JwtService.getToken()}`;
    },

    setMandantHeader() {
        Vue.axios.defaults.headers.common['X-anmeldeliste-M'] = JwtService.getMandant();
    },

    mount401Interceptor() {
        this._401interceptor = Vue.axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.request.status == 401) {
                    auth.logout();
                }
                // If error was not 401 just reject as is
                throw error;
            }
        );
    },

    removeJwtHeader() {
        delete Vue.axios.defaults.headers.common['Authorization'];
    },

    removeMandantHeader() {
        delete Vue.axios.defaults.headers.common['X-anmeldeliste-M'];
    },

    unmount401Interceptor() {
        // Eject the interceptor
        Vue.axios.interceptors.response.eject(this._401interceptor);
    },

    cleanUp() {
        this.removeJwtHeader();
        this.removeMandantHeader();
        this.unmount401Interceptor();
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`[anmeldeliste] ApiService ${error}`);
        });
    },

    get(resource, slug = '') {
        return Vue.axios.get(`${resource}/${slug}`).catch(error => {
            throw new Error(`[anmeldeliste] ApiService ${error}`);
        });
    },

    getPdf(resource, slug = '') {
        return Vue.axios
            .get(`${resource}/${slug}`, {
                responseType: 'arraybuffer',
            })
            .catch(error => {
                throw new Error(`[anmeldeliste] ApiService ${error}`);
            });
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    changeResetPasswort(resource, params) {
        return Vue.axios.post(`${resource}`, params, {
            // headers: { 'Content-Type': 'text/plain' }
        });
    },

    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    fileUpload(resource, file, additionalFormData) {
        const formData = new FormData();
        for (let key in additionalFormData) {
            formData.append(key, additionalFormData[key]);
        }
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return Vue.axios.post(`${resource}`, formData, config);
    },

    delete(resource, slug) {
        return Vue.axios.delete(`${resource}/${slug}`).catch(error => {
            throw new Error(`[anmeldeliste] ApiService ${error}`);
        });
    },
};

export default ApiService;
