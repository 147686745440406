const ID_TOKEN_KEY = 'hoerb_id_token';
const ID_MANDANT_KEY = 'hoerb_id_mand';
const ID_MANDANT_KEY_LAST = 'hoerb_id_mand_last';

export const getToken = () => window.localStorage.getItem(ID_TOKEN_KEY);

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const getMandant = () => window.localStorage.getItem(ID_MANDANT_KEY);

export const getLastSelectedMandantID = () => window.localStorage.getItem(ID_MANDANT_KEY_LAST);

export const saveMandant = token => {
    window.localStorage.setItem(ID_MANDANT_KEY, token);
    window.localStorage.setItem(ID_MANDANT_KEY_LAST, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyMandant = () => {
    window.localStorage.removeItem(ID_MANDANT_KEY);
};

export default { getToken, saveToken, destroyToken, getMandant, saveMandant, destroyMandant, getLastSelectedMandantID };
