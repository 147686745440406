import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import { sessionStoragePlugin } from './plugins';
import auth from '@/app/auth/auth.store';
import account from '@/app/account/account.store';
import user from '@/app/user/user.store';
import tenant from '@/app/tenant/tenant.store';
import rwa from '@/app/rwa/rwa.store';
import useradministration from '@/app/administration/useradministration.store';
import admin from '@/app/administration/admin.store';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        packageVersion: JSON.stringify(require('../../package.json').version),
    },
    getters: {
        appVersion: state => state.packageVersion,
    },
    modules: {
        common,
        auth,
        account,
        user,
        tenant,
        rwa,
        useradministration,
        admin,
    },
    plugins: [sessionStoragePlugin],
});
