import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'rwas';

const RwaService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getMeta(listId) {
        const path = `${listId}/meta`;
        return ApiService.get(resource, path);
    },
    async getPdf(listId) {
        const path = `${listId}/pdf`;
        return ApiService.getPdf(resource, path);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async delete(id) {
        return ApiService.delete(resource, id);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default RwaService;
