import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const phonenumberutils = {
    methods: {
        isMobile(telnr) {
            try {
                if (telnr === '') {
                    return true;
                }
                const number = phoneUtil.parseAndKeepRawInput(telnr, 'AT');
                return phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE;
            } catch (e) {
                return false;
            }
        },
        isValidNumber(telnr) {
            try {
                if (telnr === '') {
                    return true;
                }
                const number = phoneUtil.parseAndKeepRawInput(telnr, 'AT');
                return phoneUtil.isValidNumber(number, 'AT');
            } catch (e) {
                return false;
            }
        },
    },
};
