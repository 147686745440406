import Vue from 'vue';
import TenantService from './tenant.service';
import * as _ from 'lodash';
import ApiService from '@/common/api.service';

const defaults = {
    item: {
        _id: null,
        list: [],
        images: [],
        name: '',
        tenantName: '',
        url: '',
        user: [],
        hasRoleTenantAdmin: false,
        location: {
            coordinates: [],
            type: 'Point',
        },
    },
    list: [],
    currentPage: 1,
    totalPages: 1,
};

const tenant = {
    namespaced: true,

    state: Object.assign({}, defaults),

    mutations: {
        update(state, data) {
            for (let i = 0; i < data.list.length; i++) {
                let aList = data.list[i];
                if (Array.isArray(aList.images) && aList.images.length > 0) {
                    aList.src = ApiService.apiUrl() + 'blobs/' + aList.images[0] + '/image';
                }
            }

            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            for (let i = 0; i < data.docs.length; i++) {
                let aList = data.docs[i];
                if (Array.isArray(aList.images) && aList.images.length > 0) {
                    aList.src = ApiService.apiUrl() + 'blobs/' + aList.images[0] + '/image';
                }
            }
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(defaults.item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(defaults.list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            delete client_data._id;
            delete client_data.__v;
            delete client_data.list;
            delete client_data.user;
            delete client_data.tenantName;
            delete client_data.hasRoleTenantAdmin;
            const { data } = await TenantService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            let id = client_data._id;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.list;
            delete client_data.tenantName;
            delete client_data.hasRoleTenantAdmin;

            let showToast = true;
            if (client_data.showToast != null) {
                showToast = client_data.showToast;
                delete client_data.showToast;
            }
            const { data } = await TenantService.update(id, client_data, showToast);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await TenantService.get(id);
            if (!data.images) {
                data.images = [];
            }
            context.commit('update', data);
            return data;
        },
        async getByUrl(context, id) {
            const { data } = await TenantService.getByUrl(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await TenantService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let totalPages = data.pages;

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await TenantService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
        async deleteList(context, client_data) {
            const { listId, tenant } = client_data;
            const slug = `list/${tenant}/${listId}`;
            await TenantService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default tenant;
