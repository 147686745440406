import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'users';
const resourceFindByMail = 'users/find';
const resourceSignUp = resource + '/signup';
const resourceResendSignUpMail = resource + '/resendSignUpMail';
const resourceChangePassword = 'users/changepassword';
const resourceResetPassword = 'users/reset';
const resourceResetPasswordFinish = 'users/reset/';
const resourceConfirmation = 'users/confirmation/';

const AccountService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getByMail(params) {
        let promise = null;
        promise = await ApiService.post(resourceFindByMail, params);

        return promise;
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async signup(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resourceSignUp, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async resendSignUpMail(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resourceResendSignUpMail, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async changePassword(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resourceChangePassword, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async resetPassword(params) {
        let promise = null;
        try {
            promise = await ApiService.changeResetPasswort(resourceResetPassword, params);
            Toast.showSaveOkToastI18n('global.messages.toast.email_sent');
        } catch (e) {
            console.log('e.response.data: ', e.response.data);
            if (e.response.data.err === 'could not find user') {
                Toast.showSaveErrorToastI18n('global.messages.toast.email_not_registered');
            } else {
                Toast.showSaveErrorToastI18n();
            }
        }
        return promise;
    },
    async resetPasswordFinish(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.post(resourceResetPasswordFinish + slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async confirmation(slug) {
        let promise = null;
        try {
            promise = await ApiService.post(resourceConfirmation + slug);
            Toast.showSaveOkToastI18n('global.messages.toast.account_activated');
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default AccountService;
