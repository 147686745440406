import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'tenants';

const TenantService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getByUrl(slug) {
        return ApiService.get(resource + '/url', slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params, showToast = true) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            if (showToast) {
                Toast.showSaveOkToastI18n();
            }
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default TenantService;
