import 'babel-polyfill';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/main.css';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import store from './store';
import App from './App.vue';
import { sync } from 'vuex-router-sync';
import router from './common/router';
import i18n from './i18n';
import ApiService from './common/api.service';
import Toast from './common/toast';
import moment from 'moment';
import VueMoment from 'vue-moment';
import passwordcomp from '@/components/password';
import VuejsDialog from 'vuejs-dialog';
const HelloJs = require('hellojs/dist/hello.all.min.js');
const VueHello = require('vue-hellojs');
import ConfigService from './common/config.service';
import VueBrowserUpdate from '@sum.cumo/vue-browserupdate';
import './registerServiceWorker';
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTrashAlt,
    faPencilAlt,
    faCopy,
    faFilePdf,
    faPlus,
    faSortDown,
    faSortUp,
    faSave,
    faBan,
    faFileAlt,
    faFolderOpen,
    faFilePlus,
    faFileInvoiceDollar,
    faTools,
    faIndustry,
    faAddressCard,
    faTags,
    faFileImage,
    faAlignLeft,
    faCalculator,
    faMoneyBill,
    faMoneyBillAlt,
    faBook,
    faChartBar,
    faBullseyeArrow,
    faClock,
    faArchive,
    faCartArrowDown,
    faCog,
    faCogs,
    faUsersCog,
    faTasks,
    faInfoCircle,
    faDotCircle,
    faCoins,
    faFileTimes,
    faCheck,
    faRedo,
    faArrowLeft,
    faArrowRight,
    faLongArrowLeft,
    faLongArrowRight,
    faUser,
    faUserPlus,
    faUserCheck,
    faSignIn,
    faSignOut,
    faTachometerAltFast,
    faKey,
    faIdBadge,
    faPlayCircle,
    faAngleUp,
    faFlagCheckered,
    faProjectDiagram,
    faMapMarkerSmile,
    faCalendarCheck,
    faTicket,
    faBold,
    faItalic,
    faUnderline,
    faH1,
    faH2,
    faH3,
    faListUl,
    faListOl,
    faExternalLinkSquare,
    faPencil,
    faPen,
    faPollPeople,
    faPrint,
    faTimes,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPencilAlt);
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import ProfileWarning from './components/ProfileWarning';
import Validation from './components/Validation';
import decimal from './components/decimal';
import buttonspinner from '@/common/buttonspinner';
library.add(faFacebook);
library.add(faGoogle);
library.add(faPencil);
library.add(faTrashAlt);
library.add(faCopy);
library.add(faFilePdf);
library.add(faPlus);
library.add(faSortDown);
library.add(faSortUp);
library.add(faSave);
library.add(faBan);
library.add(faFileAlt);
library.add(faFolderOpen);
library.add(faFilePlus);
library.add(faFileInvoiceDollar);
library.add(faTools);
library.add(faIndustry);
library.add(faAddressCard);
library.add(faTags);
library.add(faFileImage);
library.add(faAlignLeft);
library.add(faCalculator);
library.add(faMoneyBill);
library.add(faMoneyBillAlt);
library.add(faBook);
library.add(faChartBar);
library.add(faBullseyeArrow);
library.add(faClock);
library.add(faArchive);
library.add(faCartArrowDown);
library.add(faCog);
library.add(faCogs);
library.add(faUsersCog);
library.add(faTasks);
library.add(faInfoCircle);
library.add(faDotCircle);
library.add(faCoins);
library.add(faFileTimes);
library.add(faCheck);
library.add(faRedo);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faLongArrowLeft);
library.add(faLongArrowRight);
library.add(faUser);
library.add(faUserPlus);
library.add(faUserCheck);
library.add(faSignIn);
library.add(faSignOut);
library.add(faTachometerAltFast);
library.add(faKey);
library.add(faIdBadge);
library.add(faPlayCircle);
library.add(faAngleUp);
library.add(faFlagCheckered);
library.add(faProjectDiagram);
library.add(faMapMarkerSmile);
library.add(faCalendarCheck);
library.add(faTicket);
library.add(faBold);
library.add(faItalic);
library.add(faUnderline);
library.add(faH1);
library.add(faH2);
library.add(faH3);
library.add(faListUl);
library.add(faListOl);
library.add(faExternalLinkSquare);
library.add(faPencil);
library.add(faPen);
library.add(faPollPeople);
library.add(faPrint);
library.add(faTimes);

Vue.component('font-awesome-icon', FontAwesomeIcon);
// Vue.component('kinderschnellanlage', kinderschnellanlage);
Vue.component('buttonspinner', buttonspinner);
Vue.component('profileWarning', ProfileWarning);
Vue.component('v-select', vSelect);
Vue.component('passwordcomp', passwordcomp);
// Vue.component('slotgeneration', SlotGeneration);
Vue.component('validation', Validation);
Vue.component('decimal', decimal);
Vue.component('pulse-loader', PulseLoader);

Vue.config.productionTip = false;

// Http and Auth plugins
// Vue.use(http);
// Vue.use(auth);

ApiService.init();
// Sync router to store, as `store.state.route`.
sync(store, router);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');

Vue.filter('dob', function(value) {
    if (value) {
        return moment().diff(value, 'years');
        // Math.abs(birthday.diff(moment(), 'years'))
        // return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});

HelloJs.init(
    {
        google: ConfigService.getConfigIfExists('googleclientid'),
        facebook: ConfigService.getConfigIfExists('facebookclientid'),
    },
    {
        redirect_uri: ConfigService.getConfigIfExists('socialLoginRedirect'),
    }
);
Vue.use(VueHello, HelloJs);
Vue.use(VuejsDialog);
Vue.use(Vuelidate);
Vue.use(VueLodash, { lodash });
moment.locale('de');

Vue.use(VueMoment, { moment });
Vue.use(VueBrowserUpdate, {
    options: {
        required: { e: -1, f: -3, o: -2, s: -1, c: -3 },
        insecure: true,
        unsupported: true,
        mobile: false,
        style: 'bottom',
        reminder: 0,
        reminderClosed: 2,
        no_permanent_hide: true,
    },
});
Vue.browserUpdate.onshow(() => {});
Toast.init();

// Vue.filter('capitalize', function (value) {
//     if (!value) return '';
//     value = value.toString();
//     return value.charAt(0).toUpperCase() + value.slice(1);
// });
