import Vue from 'vue';
import UseradministrationService from './useradministration.service';
import * as _ from 'lodash';

const getDefaultItem = () => ({
    id: null,
    isSocialLogin: false,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 1,
    totalPages: 1,
    roles: {},
});

const useradministration = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateRoles(state, data) {
            Vue.set(state, 'roles', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await UseradministrationService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const id = client_data._id;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.confirmationToken;
            delete client_data.resetPasswordExpires;
            delete client_data.resetPasswordToken;
            delete client_data.updatedAt;
            delete client_data.createdAt;

            const { data } = await UseradministrationService.update(id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await UseradministrationService.get(id);
            context.commit('update', data);
            return data;
        },
        async getRoles(context) {
            const { data } = await UseradministrationService.getRoles();
            context.commit('updateRoles', data);
            return data;
        },
        async query(context, params) {
            const { data } = await UseradministrationService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let totalPages = data.pages;

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await UseradministrationService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        roles(state) {
            return state.roles;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default useradministration;
