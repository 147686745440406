import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '@/store';

Vue.use(Router);

function guardRoute(to, from, next) {
    const auth = store.state.auth;

    if (!auth.isLoggedIn) {
        next({
            path: '/admin/login',
            query: {
                redirect: to.fullPath,
            },
        });
    } else {
        next();
    }
}

export default new Router({
    base: '/app',
    // mode: 'history',
    routes: routes.map(route => ({
        name: route.name,
        path: route.path,
        component: route.component,
        beforeEnter: (to, from, next) => {
            // Setup some per-page stuff.
            document.title = 'GEZE -- ' + route.title;
            store.dispatch('common/updateTitle', route.title);
            // store.dispatch('common/updateLayout', route.layout);

            // Auth navigation guard.
            if (!route.isPublic) return guardRoute(to, from, next);

            next();
        },
    })),
});
