<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link :to="{ name: 'home' }" class="navbar-brand">
            <img src="~/@/assets/logo.png" height="40" class="d-inline-block align-top" alt="" />
        </router-link>

        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav w-100">
                <li class="nav-item">
                    <router-link v-if="isAuthenticated" :to="{ name: 'rwa_list' }" class="nav-link">
                        <font-awesome-icon :icon="['fad', 'bullseye-arrow']" />&nbsp;
                        <span>Suchen</span>
                    </router-link>
                </li>

                <li class="nav-item dropdown mobile-left">
                    <a
                        id="navbarDropdown"
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <font-awesome-icon v-if="isAuthenticated" :icon="['fad', 'user-check']" />
                        <font-awesome-icon v-if="!isAuthenticated" :icon="['fad', 'user']" />
                        <img v-if="userPicture" class="profilepicture" :src="userPicture" />
                        <span v-if="isAuthenticated">&nbsp;{{ friendlyUsername }}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <!--                        <router-link-->
                        <!--                            v-if="isAuthenticated"-->
                        <!--                            :to="{ name: 'settings', params: { slug: userId } }"-->
                        <!--                            class="dropdown-item"-->
                        <!--                        >-->
                        <!--                            <font-awesome-icon :icon="['fad', 'id-badge']" />&nbsp;-->
                        <!--                            <span>{{ $t('settings') }}</span>-->
                        <!--                        </router-link>-->
                        <div v-if="isAuthenticated && hasRoleAdmin" class="dropdown-divider"></div>

                        <router-link
                            v-if="isAuthenticated && hasRoleAdmin"
                            :to="{ name: 'useradministration_list' }"
                            class="dropdown-item"
                        >
                            <font-awesome-icon :icon="['fad', 'user']" />&nbsp;
                            <span>{{ $t('useradmin') }}</span>
                        </router-link>

                        <div v-if="isAuthenticated" class="dropdown-divider"></div>

                        <a v-if="isAuthenticated" id="logout" href="" class="dropdown-item" @click="logout()">
                            <font-awesome-icon :icon="['fad', 'sign-out']" />&nbsp;
                            <span>{{ $t('logout') }}</span>
                        </a>

                        <router-link v-if="!isAuthenticated" :to="{ name: 'login' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'sign-in']" />&nbsp;
                            <span>{{ $t('login') }}</span>
                        </router-link>

                        <div v-if="isAuthenticated && hasRoleAdmin" class="dropdown-divider"></div>

                        <div v-if="isAuthenticated && hasRoleAdmin" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'info-circle']" />
                            <span class="menu-text">
                                Version: {{ $store.getters.appVersion.substr(1, $store.getters.appVersion.length - 2) }}
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import auth from '../auth/auth.helpers';
import { mapGetters } from 'vuex';

export default {
    name: 'Navbar',
    props: {
        msg: String,
    },
    computed: {
        ...mapGetters({
            hasRoleAdmin: 'auth/hasRoleAdmin',
        }),
        friendlyUsername() {
            return this.$store.state.auth.friendlyUsername;
        },
        userId() {
            return this.$store.state.auth.userId;
        },
        userPicture() {
            return this.$store.state.auth.picture;
        },
        // selectedMandant() {
        //     return this.$store.state.mandant.selectedMandant;
        // },
        mandanten() {
            return this.$store.state.mandant.mandanten;
        },
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        },
        isSocialLogin() {
            return this.$store.state.auth.isSocialLogin;
        },
    },

    methods: {
        logout: function() {
            const hello = this.hello;
            const isSocialLogin = this.isSocialLogin;
            if (isSocialLogin != null && isSocialLogin != '') {
                hello(isSocialLogin)
                    .logout()
                    .then(() => {});
            }
            auth.logout();
        },

        // changeMandant: function (mandant) {
        //     // this.$store.dispatch("mandant/selectMandant",mandant).then( () => {
        //     //         router.push({name: 'home'});
        //     //     });
        // },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<!--language=json -->
<i18n>
    {
        "de": {
            "main": "Zugang",
            "settings": "Profil",
            "password": "Passwort",
            "sessions": "Sitzungen",
            "login": "Anmelden",
            "logout": "Abmelden",
            "useradmin": "Useradministration",
            "near": "Veranstalter in der Nähe",
            "register": "Registrierung"
        },
        "en": {
            "main": "Account",
            "settings": "Profile",
            "password": "Password",
            "sessions": "Sessions",
            "login": "Sign in",
            "logout": "Sign out",
            "useradmin": "Useradministration",
            "near": "Lists near to you",
            "register": "Register"
        }
    }

</i18n>
