<template>
    <input
        v-model="displayValue"
        :disabled="disabledValue"
        type="text"
        class="form-control"
        style="text-align: left"
        @blur="isInputActive = false"
        @focus="isInputActive = true"
    />
</template>

<script>
export default {
    name: 'DecimalInput',
    props: {
        value: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            isInputActive: false,
        };
    },
    computed: {
        displayValue: {
            get: function() {
                if (this.isInputActive) {
                    return this.value;
                } else {
                    let num = this.value;
                    if (num == null) {
                        return;
                    }
                    return num;
                }
            },
            set: function(modifiedValue) {
                let newValue = modifiedValue.replace(/,/, '.');
                newValue = parseFloat(newValue);
                if (isNaN(newValue)) {
                    newValue = null;
                }
                this.$emit('input', newValue);
                this.$emit('change', newValue);
            },
        },
        disabledValue: {
            get: function() {
                return this.disabled;
            },
        },
    },
};
</script>
