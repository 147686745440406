const config = {
    'gezerwadev.hilarion5.software': {
        backendBaseUrl: 'https://gezerwadev.hilarion5.software/api/v1/',
        socialLoginActive: false,
        socialLoginRedirect: '/app/',
        googleclientid: 'xx',
        facebookclientid: 'yy',
    },
    'gezerwa.hilarion5.software': {
        backendBaseUrl: 'https://gezerwa.hilarion5.software/api/v1/',
        socialLoginActive: false,
        socialLoginRedirect: '/app/',
        googleclientid: 'xx',
        facebookclientid: 'yy',
    },
    localhost: {
        backendBaseUrl: 'http://localhost:3000/api/v1/',
        socialLoginActive: true,
        socialLoginRedirect: '/',
        googleclientid: 'yy',
        facebookclientid: 'yy',
    },
};
export default config;
