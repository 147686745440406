import Vue from 'vue';
import Toasted from 'vue-toasted';
import VueI18n from '@/i18n';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

const Toast = {
    options: {
        theme: 'outline',
        position: 'bottom-right',
        duration: 3000,
        iconPack: 'fontawesome',
    },
    dialogOpt: {
        okText: VueI18n.tc('entity.action.delete'),
        cancelText: VueI18n.tc('entity.action.cancel'),
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
    },

    init() {
        Vue.use(Toasted, this.options);
        Vue.use(VuejsDialog);
    },

    showSaveOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.save_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showSaveOkToastText(text, displayToast = true) {
        if (displayToast) {
            Vue.toasted.show(text, { icon: 'check' });
        }
    },

    showDeleteOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.delete_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showCreateOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.create_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    askDelete(i18nConst) {
        if (!i18nConst) i18nConst = 'entity.delete.msg';
        let promise = Vue.dialog
            .confirm(VueI18n.tc(i18nConst), this.dialogOpt)
            .then(() => true)
            .catch(() => false);

        return promise;
    },

    showSaveErrorToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.save_nok';
        Vue.toasted.show(VueI18n.tc(i18nConst), {
            icon: 'warning',
            theme: 'bubble',
            duration: '8000',
        });
    },
    showSaveErrorToastText(text) {
        Vue.toasted.show(text, {
            icon: 'warning',
            theme: 'bubble',
            duration: '8000',
        });
    },
};

export default Toast;
