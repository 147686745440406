/**
 * Every route becomes a chunk, loaded only when used.
 * Reduces size of initial App load.
 */
const routes = [
    {
        name: 'login',
        path: '/admin/login',
        component: () => import(/* webpackChunkName: "login" */ '@/app/login/login.vue'),
        title: 'Login',
        isPublic: true,
    },

    {
        name: 'useradministration_list',
        path: '/admin/useradministration',
        component: () =>
            import(
                /* webpackChunkName: "useradministration/useradministration_list" */ '@/app/administration/useradministration_list.vue'
            ),
        title: 'Useradministation',
        isPublic: false,
    },
    {
        name: 'register',
        path: '/register',
        component: () => import(/* webpackChunkName: "register" */ '@/app/account/settings/register.vue'),
        title: 'Registrierung',
        isPublic: false,
    },
    // {
    //     name: 'admin_dashboard',
    //     path: '/admin/dashboard',
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "useradministration/admin_dashboard" */ '@/app/administration/admin_dashboard.vue'
    //         ),
    //     title: 'Dashboard',
    //     isPublic: false,
    // },
    {
        name: 'useradministration',
        path: '/admin/useradministration/:slug',
        component: () =>
            import(/* webpackChunkName: "useradministration" */ '@/app/administration/useradministration.vue'),
        title: 'Useradministation',
        isPublic: false,
    },
    // {
    //     name: 'confirmation',
    //     path: '/confirmation',
    //     component: () => import(/* webpackChunkName: "confirmation" */ '@/app/account/settings/confirmation.vue'),
    //     title: 'Confirmation',
    //     isPublic: true,
    // },
    {
        name: 'home',
        path: '/',
        component: () => import(/* webpackChunkName: "rwa" */ '@/app/rwa/rwa.vue'),
        title: 'START',
        isPublic: false,
    },

    {
        name: 'start',
        path: '/rwa/:slug',
        component: () => import(/* webpackChunkName: "rwa" */ '@/app/rwa/rwa.vue'),
        title: 'START',
        isPublic: false,
    },
    {
        name: 'copy',
        path: '/rwaCopy/:slug',
        component: () => import(/* webpackChunkName: "rwa" */ '@/app/rwa/rwa.vue'),
        title: 'RWA KOPIEREN',
        isPublic: false,
    },
    // {
    //     name: 'settings',
    //     path: '/admin/settings/:slug',
    //     component: () => import(/* webpackChunkName: "settings" */ '@/app/account/settings/settings.vue'),
    //     title: 'Settings',
    //     isPublic: false,
    // },
    {
        name: 'rwa_list',
        path: '/rwalist/search/',
        component: () => import(/* webpackChunkName: "tenant_list" */ '@/app/rwa/rwa_list.vue'),
        title: 'Suchen',
        isPublic: false,
    },
    // {
    //     name: 'tenant',
    //     path: '/admin/tenant/:slug',
    //     component: () => import(/* webpackChunkName: "tenant" */ '@/app/tenant/tenant.vue'),
    //     title: 'Mandant',
    //     isPublic: false,
    // },
    // {
    //     name: 'changepassword',
    //     path: '/admin/changepassword/:slug',
    //     component: () => import(/* webpackChunkName: "changepassword" */ '@/app/account/settings/password.vue'),
    //     title: 'Passwort',
    //     isPublic: false,
    // },
    // {
    //     name: 'passwordreset',
    //     path: '/admin/passwordreset',
    //     component: () => import(/* webpackChunkName: "passwordreset" */ '@/app/account/settings/passwordreset.vue'),
    //     title: 'Account',
    //     isPublic: true,
    // },
    // {
    //     name: 'passwordresetfinish',
    //     path: '/admin/reset/finish',
    //     component: () =>
    //         import(/* webpackChunkName: "passwordresetfinish" */ '@/app/account/settings/passwordreset_finish.vue'),
    //     title: 'Account',
    //     isPublic: true,
    // },
    // {
    //     name: 'list_allmyparticipants',
    //     path: '/allmyparticipants',
    //     component: () => import(/* webpackChunkName: "list_edit" */ '@/app/list/list_allmyparticipants.vue'),
    //     title: 'Anmeldeliste',
    //     isPublic: true,
    // },
    // {
    //     name: 'register',
    //     path: '/register',
    //     component: () => import(/* webpackChunkName: "register" */ '@/app/account/settings/register.vue'),
    //     title: 'Registrierung',
    //     isPublic: true,
    // },
    // {
    //     name: 'tenantStart',
    //     path: '/xx/:slug/',
    //     component: () => import(/* webpackChunkName: "tenantStart" */ '@/app/tenant/tenant_start.vue'),
    //     title: 'Anmeldelisten',
    //     isPublic: true,
    // },
    // {
    //     name: 'participant_list',
    //     path: '/admin/participant_list/',
    //     component: () => import(/* webpackChunkName: "participant_list" */ '@/app/participant/participant_list.vue'),
    //     title: 'Participants',
    //     isPublic: false,
    // },
    // {
    //     name: 'participant',
    //     path: '/admin/participant/:slug',
    //     component: () => import(/* webpackChunkName: "participant" */ '@/app/participant/participant.vue'),
    //     title: 'Participant',
    //     isPublic: false,
    // },
];

export default routes;
