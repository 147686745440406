import Vue from 'vue';
import AdminService from './admin.service';

const defaults = {
    item: {
        id: null,
    },
    list: [],
    allDailyParticipants: [],
    currentPage: 1,
    totalPages: 1,
};

const admin = {
    namespaced: true,

    state: Object.assign({}, defaults),

    mutations: {
        updateAllDailyParticipants(state, data) {
            Vue.set(state, 'allDailyParticipants', data);
        },
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(defaults.item)));
            Vue.set(state, 'allDailyParticipants', JSON.parse(JSON.stringify(defaults.allDailyParticipants)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(defaults.list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async getAllDailyParticipants(context, dateYYYYMMDD) {
            const { data } = await AdminService.getAllDailyParticipants(dateYYYYMMDD);
            context.commit('updateAllDailyParticipants', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        allDailyParticipants(state) {
            return state.allDailyParticipants;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default admin;
