import config from '@/config/configuration';

const ConfigService = {
    getConfigIfExists(key) {
        if (config[window.location.hostname] && config[window.location.hostname][key]) {
            return config[window.location.hostname][key];
        }
        switch (key) {
            case 'backendBaseUrl':
                return '/';
            case 'socialLoginActive':
                return false;
            case 'socialLoginRedirect':
                return '/';
            case 'googleclientid':
                return '';
            case 'facebookclientid':
                return '';
            default:
                return '';
        }
    },
};

export default ConfigService;
