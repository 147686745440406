/* eslint-disable no-console */
import Vue from 'vue';
import router from '@/common/router';
import store from '@/store';
import auth from './index';
import JwtService from '@/common/jwt.service';
import ApiService from '@/common/api.service';

const LOGIN_URL = 'users/login';

// const CLIENT_SECRET = 'ZGVtb2FwcDpkZW1vcGFzcw==' // Base64(client_id:client_secret) "demoapp:demopass"

export default {
    login(creds, redirect, picture, sociallogin, callback) {
        let login_json = {};
        if (sociallogin != null) {
            login_json.email = creds.email;
            login_json.first_name = creds.firstName;
            login_json.last_name = creds.lastName;
            login_json.isSocialLogin = creds.isSocialLogin;
            login_json.accesstoken = creds.accesstoken;
        } else {
            login_json.email = creds.username;
        }
        login_json.password = creds.password;

        Vue.axios({
            method: 'post',
            url: LOGIN_URL,
            headers: {
                // 'Authorization': 'Basic ' + CLIENT_SECRET
            },
            data: login_json,
        })
            .then(response => {
                auth.storeToken(response);

                if (redirect) router.push(redirect);
                if (callback) callback(true, null);
            })
            .catch(error => {
                let errorMessage = null;

                if (error.response) errorMessage = error.response.data;
                else if (error.request) errorMessage = 'no response from server';
                else errorMessage = error.message;

                if (callback) callback(false, errorMessage);
            });
    },
    logout() {
        store.dispatch('common/clear');
        JwtService.destroyToken();
        JwtService.destroyMandant();
        ApiService.cleanUp();
        router.push({ name: 'login' });
    },
    async authSocial(network, hello) {
        let scopeToUse = '';
        if (network === 'google') {
            scopeToUse =
                'https://www.googleapis.com/auth/userinfo.profile, https://www.googleapis.com/auth/userinfo.email';
        } else {
            scopeToUse = 'public_profile,email';
        }

        return hello(network)
            .login({
                scope: scopeToUse,
                force: false,
            })
            .then(async () => {
                const authRes = hello(network).getAuthResponse();
                return await hello(network)
                    .api('me')
                    .then(function(json) {
                        const profile = json;
                        let x = document.getElementById('snackbar');
                        x.innerHTML =
                            "<img class='profilepicture' src='" +
                            profile.thumbnail +
                            "' width=24/>Connected to " +
                            network +
                            ' as ' +
                            profile.name;
                        x.className = 'show';
                        setTimeout(function() {
                            x.className = x.className.replace('show', '');
                        }, 3000);

                        return {
                            firstName: profile.first_name,
                            lastName: profile.last_name,
                            tel: '***',
                            password: '***',
                            email: profile.email,
                            role: 3,
                            isSocialLogin: network,
                            accesstoken: authRes.access_token,
                            thumbnail: profile.thumbnail,
                        };
                    });
            });
    },
};
