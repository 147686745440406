var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.account != null)?_c('div',[(
            !(_vm.account.userParticipant && _vm.account.userParticipant.length > 0) ||
                !(_vm.account.tel && _vm.account.tel !== '') ||
                !_vm.isMobileTel ||
                !_vm.isValidMobileTel
        )?_c('div',{staticClass:"alert alert-warning col-12",attrs:{"role":"alert"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Ihr Profil ist fast fertig!")]),(!(_vm.account.userParticipant && _vm.account.userParticipant.length > 0))?_c('div',[_vm._v(" Sie haben noch keine Kinder/Personen erfasst, die sie später anmelden wollen. ")]):_vm._e(),(!(_vm.account.tel && _vm.account.tel !== ''))?_c('div',[_vm._v(" Sie haben noch kein Telefonnummer eingetragen - Sie können somit keine SMS empfangen. ")]):_vm._e(),(!_vm.isMobileTel || !_vm.isValidMobileTel)?_c('div',[_vm._v(" Die eingegebene Telefonnummer ist keine Mobiltelefonnummer. Sie können vorrausichtlich keine SMS erhalten ")]):_vm._e(),_c('br'),_c('hr'),_vm._v(" Sie sollten das hier tun: "),_c('router-link',{staticClass:"ml-3 btn btn-outline-primary",attrs:{"to":{
                name: 'settings',
                params: {
                    slug: _vm.account._id,
                    redirect:
                        _vm.tenant && _vm.list
                            ? { name: 'list_list', params: { tenant: _vm.tenant.url, slug: _vm.list._id } }
                            : null,
                },
            },"tag":"button"}},[_c('font-awesome-icon',{attrs:{"icon":['fad', 'id-badge']}}),_vm._v("  "),_c('span',[_vm._v(_vm._s(_vm.$t('Profil')))])],1)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }