import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const numberFormats = {
    'de-DE': {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        percent: {
            style: 'percent',
            minimumFractionDigits: 1,
        },
    },
    'en-US': {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        percent: {
            style: 'percent',
            minimumFractionDigits: 1,
        },
    },
};

const dateTimeFormats = {
    'de-DE': {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
    },
    en: {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
    },
};

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'de',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    silentTranslationWarn: true,
    numberFormats,
    dateTimeFormats,
    messages: loadLocaleMessages(),
});
