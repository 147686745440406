import Vue from 'vue';

const defaults = {
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    friendlyUsername: null,
    role: null,
    userId: null,
    authorities: [],
    picture: null,
    isSocialLogin: null,
    accesstoken: null,
};

const auth = {
    namespaced: true,

    state: Object.assign({}, defaults),

    mutations: {
        update(state, data) {
            state = Object.assign({}, defaults, data);
        },
        updateFriendlyUsername(state, data) {
            Vue.set(state, 'friendlyUsername', data);
        },
        updateAuthorities(state, data) {
            Vue.set(state, 'authorities', data);
        },
        clear(state) {
            state = Object.assign(state, defaults);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        update({ commit }, data) {
            commit('update', data);
        },
        updateFriendlyUsername(context, data) {
            context.commit('updateFriendlyUsername', data);
        },
        updateAuthorities(context, data) {
            context.commit('updateAuthorities', data);
        },
    },

    getters: {
        hasRoleUser(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_USER')) return true;
            }
            return false;
        },
        hasRoleAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ADMIN')) return true;
            }
            return false;
        },
    },
};

export default auth;
