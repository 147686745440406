import Vue from 'vue';
import RwaService from './rwa.service';
import * as _ from 'lodash';
import download from 'downloadjs';
import ApiService from '@/common/api.service';
import ListService from '../list/list.service';
// import moment from 'moment'

const defaults = {
    item: {
        technischerplatz: '',
        objekt: '',
        ansprech: '',
        ansprechtel: '',
        ansprechmail: '',
        plz: '',
        adresse: '',
        hersteller: '',
        herstellersonst: '',
        typ: '',
        typsonst: '',
        standort: '',
        ampere: '',
        baujahrmonat: '',
        techniker: '',
        baujahr: '',
        akkutyp: '',
        akkuhersteller: '',
        akkubaujahrmonat: '',
        akkubaujahr: '',
        posstandort: '',
        letztewartungmonat: '',
        letztewartung: '',
        antriebe: [],
        rwantriebe: [],
        rwatasten: [],
        luetasten: [],
        rauchmelder: [],
        verantriebe: [],
        _id: null,
    },
    meta: {
        availableFreeSeats: 0,
        usedFreeSeats: 0,
        availableSlots: 0,
        usedSlots: 0,
        bookableInFuture: false,
        bookable: false,
    },
    freeSlots: [],
    participants: [],
    myParticipants: [],
    allMyParticipants: [],
    imageUrl: null,
    list: [],
    currentPage: 1,
    totalPages: 1,
};

const rwa = {
    namespaced: true,

    state: Object.assign({}, defaults),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateMeta(state, data) {
            Vue.set(state, 'meta', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(defaults.item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(defaults.list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            delete client_data._id;
            delete client_data.__v;
            delete client_data.isVisible;
            const { data } = await RwaService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            //todo besser object copy ?! -> not delete ??
            const { _id } = client_data;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.participant;
            delete client_data.participantWait;
            delete client_data.isVisible;
            const { data } = await RwaService.update(_id, client_data);
            context.commit('update', data);
            return data;
        },
        async loadfromstring(context, data) {
            context.commit('update', data);
        },
        async getMeta(context, id) {
            const { data } = await RwaService.getMeta(id);
            context.commit('updateMeta', data);
            return data;
        },
        async getPdf(context, client_data) {
            const { data } = await RwaService.getPdf(client_data.id);

            let filename = client_data.filename;

            if (filename) {
                filename += '.pdf';
            } else {
                filename = 'RWA.pdf';
            }

            download(new Blob([data]), filename, 'application/pdf');

            // context.commit('updateParticipants', data);
            //return data;
        },
        async get(context, id) {
            const { data } = await RwaService.get(id);
            context.commit('update', data);
            return data;
        },
        async getParticipants(context, id) {
            const { data } = await RwaService.getParticipants(id);
            context.commit('updateParticipants', data);
            return data;
        },
        async getFreeSlots(context, id) {
            const { data } = await RwaService.getFreeSlots(id);
            context.commit('updateFreeSlots', data);
            return data;
        },
        async getParticipantsPdf(context, id) {
            const { data } = await RwaService.getParticipantsPdf(id);

            download(new Blob([data]), 'dlTextBlob.pdf', 'application/pdf');

            // context.commit('updateParticipants', data);
            //return data;
        },
        async getMyParticipants(context, client_data) {
            const { listId, userId } = client_data;
            const { data } = await RwaService.getMyParticipants(listId, userId);
            context.commit('updateMyParticipants', data);
            return data;
        },
        async getAllMyParticipants(context) {
            const { data } = await RwaService.getAllMyParticipants();
            context.commit('updateAllMyParticipants', data);
            return data;
        },
        async query(context, params) {
            const { data } = await RwaService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 1;
            context.commit('updateCurrentPage', currPage);

            let totalPages = data.pages;

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await RwaService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
        async duplicate(context, client_data) {
            const { listId, tenant } = client_data;
            const slug = `${tenant}/${listId}/duplicate`;
            const { data } = await RwaService.duplicate(slug);
            context.commit('update', data);
            return data;
        },
        async deleteParticipant(context, client_data) {
            const { listId, participantId } = client_data;
            const slug = `${listId}/participant/${participantId}`;
            const { data } = await RwaService.delete(slug);
            context.commit('updateParticipants', data);

            const listDataGetResponse = await RwaService.get(listId);
            if (listDataGetResponse.status === 200) {
                context.commit('update', listDataGetResponse.data);
            }
            return data;
        },
        async addParticipant(context, client_data) {
            const { list, account, child, toWaitList } = client_data;

            const participant = {
                user_id: account._id,
                firstName: child.firstName,
                lastName: child.lastName,
                dob: child.dob,
                properties: child.properties,
            };

            const { data } = await RwaService.addParticipant(list._id, participant);

            if (toWaitList) {
                //todo -> wobei das funktioniert vermutlich schon automatisch im Backend, falls voll!
            }

            context.commit('update', data);
            return data;
        },
        async selectSlot(context, client_data) {
            const { list, slotId, account, child } = client_data;

            const participant = {
                _id: slotId,
                user_id: account._id,
                firstName: child.firstName,
                lastName: child.lastName,
                dob: child.dob,
                properties: child.properties,
            };

            const { data } = await RwaService.selectSlot(list._id, participant);

            context.commit('update', data);
            return data;
        },
        async freeSlot(context, client_data) {
            const { listId, participantId } = client_data;

            const { data } = await RwaService.freeSlot(listId, participantId);

            context.commit('update', data);
            return data;
        },
        async addSlot(context, client_data) {
            const newSlot = {
                slot: client_data.newSlot.slot,
                slotSortorder: client_data.newSlot.slotSortorder,
                properties: client_data.newSlot.properties,
            };

            const displayToast = client_data.displayToast;

            const { data } = await RwaService.addSlot(client_data.list._id, newSlot, displayToast);

            context.commit('update', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        meta(state) {
            return state.meta;
        },
        participants(state) {
            return state.participants;
        },
        freeSlots(state) {
            return state.freeSlots;
        },
        myParticipants(state) {
            return state.myParticipants;
        },
        allMyParticipants(state) {
            return state.allMyParticipants;
        },
        imageUrl(state) {
            return state.imageUrl;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default rwa;
