import Vue from 'vue';
import AccountService from './account.service';
import store from '@/store';
import router from '@/common/router';

const defaultsChild = {
    firstName: '',
    lastName: '',
    dob: null,
    positionsNummer: 0,
    properties: {},
};

const defaults = {
    account: {
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        role: '',
        userParticipant: [],
        isSocialLogin: '',
    },
    accountCreate: {
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        role: 0,
        password: '',
        isSocialLogin: '',
    },
    resetAccount: {
        email: null,
        tel: null,
    },
    meta: {
        success: false,
        error: false,
        errorEmailExists: false,
        notEqual: false,
        notFound: false,
        keyMissing: false,
    },
    pwd: {
        password: '',
        confirmPassword: '',
    },
};

const account = {
    namespaced: true,

    state: Object.assign({}, defaults),

    mutations: {
        clearAccount(state) {
            Vue.set(state, 'accountCreate', {});
        },
        update(state, data) {
            Vue.set(state, 'account', data);
            if (data.role === 1) {
                store.dispatch('auth/updateAuthorities', ['ROLE_ADMIN']);
            } else if (data.role === 2) {
                store.dispatch('auth/updateAuthorities', ['ROLE_TENANTADMIN']);
            } else if (data.role === 3) {
                store.dispatch('auth/updateAuthorities', ['ROLE_USER']);
            }
        },
        updateMeta(state, data) {
            Vue.set(state, 'meta', data);
        },
        clearMeta(state) {
            Vue.set(state, 'meta', defaults.meta);
        },
        clearPwd(state) {
            Vue.set(state, 'pwd', defaults.pwd);
        },
        clearResetAccount(state) {
            Vue.set(state, 'resetAccount', defaults.resetAccount);
        },

        addChild(state, data) {
            if (data == null) {
                data = Object.assign({}, defaultsChild);
                data.lastName = state.account.lastName;
                data.dob = Vue.moment().add(-10, 'years');
            }

            let maxPos = Math.max.apply(
                Math,
                state.account.userParticipant.map(o => o.positionsNummer || 0)
            );
            if (maxPos < 0) maxPos = 0;
            data.positionsNummer = maxPos + 1;

            state.account.userParticipant.push(data);
        },
        delChild(state, data) {
            let deleteCurrent = false;

            for (let index = 0; index < state.account.userParticipant.length; ++index) {
                let pos = state.account.userParticipant[index];
                if (data == null && pos == null) {
                    deleteCurrent = true;
                } else if (data._id != null) {
                    if (data._id == pos._id) deleteCurrent = true;
                } else {
                    if (data.positionsNummer == pos.positionsNummer) deleteCurrent = true;
                }

                if (deleteCurrent) {
                    state.account.userParticipant.splice(index, 1);
                    break;
                }
            }
        },
    },

    actions: {
        async signup(context, client_data) {
            context.commit('clearMeta');
            delete client_data.confirmPassword;
            const { data } = await AccountService.signup(client_data);
            if (data.success) {
                context.commit('clearAccount');
                //router.push({name: 'login'});
            }

            return data;
        },
        async resendSignUpMail(context, client_data) {
            context.commit('clearMeta');
            const { data } = await AccountService.resendSignUpMail(client_data);
            if (data.success) {
                context.commit('clearAccount');
                //router.push({name: 'login'});
            }
            return data;
        },
        async update(context, client_data) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            const { _id } = client_data;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.role;
            delete client_data.email;
            delete client_data.isVerified;
            delete client_data.confirmationToken;
            delete client_data.resetPasswordExpires;
            delete client_data.resetPasswordToken;
            delete client_data.createdAt;
            delete client_data.updatedAt;
            const { data } = await AccountService.update(_id, client_data);
            context.commit('update', data);
            store.dispatch('auth/updateFriendlyUsername', data.firstName);

            return data;
        },
        async updatePassword(context, client_data) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            const { _id } = client_data;
            delete client_data._id;
            await AccountService.changePassword(_id, client_data);
        },
        async resetPassword(context, email) {
            context.commit('clearMeta');
            context.commit('clearResetAccount');
            const passwordResetResponse = await AccountService.resetPassword({ email: email });
            if (passwordResetResponse && passwordResetResponse.status === 200) {
                context.commit('updateMeta', { success: true });
            }
        },
        async resetPasswordFinish(context, client_data) {
            context.commit('clearMeta');

            const { key } = client_data;
            delete client_data.key;
            const { data } = await AccountService.resetPasswordFinish(key, client_data);
            if (data.success) {
                router.push({ name: 'login' });
            }
        },
        async confirmation(context, token) {
            context.commit('clearMeta');

            const { data } = await AccountService.confirmation(token);
            if (data.success) {
                router.push({ name: 'login' });
            }
        },
        async get(context, id) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            if (id == null) id = context.rootState.auth.userId;
            const { data } = await AccountService.get(id);
            context.commit('update', data);
            return data;
        },
        async getByMail(context, email) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            if (email == null) email = context.rootState.auth.email;
            const { data } = await AccountService.getByMail(email);
            //context.commit('update', data);
            return data;
        },
        addChild(context, child) {
            if (child == null) child = Object.assign({}, defaultsChild);
            context.commit('addChild', child);
        },
        delChild(context, child) {
            context.commit('delChild', child);
        },
    },

    getters: {
        data(state) {
            return state.account;
        },
        dataCreate(state) {
            return state.accountCreate;
        },
        dataReset(state) {
            return state.resetAccount;
        },
        meta(state) {
            return state.meta;
        },
        pwd(state) {
            return state.pwd;
        },
    },
};

export default account;
