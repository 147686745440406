<template>
    <div v-if="account != null">
        <div
            v-if="
                !(account.userParticipant && account.userParticipant.length > 0) ||
                    !(account.tel && account.tel !== '') ||
                    !isMobileTel ||
                    !isValidMobileTel
            "
            class="alert alert-warning col-12"
            role="alert"
        >
            <h4 class="alert-heading">Ihr Profil ist fast fertig!</h4>
            <div v-if="!(account.userParticipant && account.userParticipant.length > 0)">
                Sie haben noch keine Kinder/Personen erfasst, die sie später anmelden wollen.
            </div>
            <div v-if="!(account.tel && account.tel !== '')">
                Sie haben noch kein Telefonnummer eingetragen - Sie können somit keine SMS empfangen.
            </div>
            <div v-if="!isMobileTel || !isValidMobileTel">
                Die eingegebene Telefonnummer ist keine Mobiltelefonnummer. Sie können vorrausichtlich keine SMS
                erhalten
            </div>
            <br />
            <hr />
            Sie sollten das hier tun:

            <router-link
                :to="{
                    name: 'settings',
                    params: {
                        slug: account._id,
                        redirect:
                            tenant && list
                                ? { name: 'list_list', params: { tenant: tenant.url, slug: list._id } }
                                : null,
                    },
                }"
                tag="button"
                class="ml-3 btn btn-outline-primary"
            >
                <font-awesome-icon :icon="['fad', 'id-badge']" />&nbsp;
                <span>{{ $t('Profil') }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { phonenumberutils } from './mixins/phonenumberutils';

export default {
    name: 'ProfileWarning',
    mixins: [phonenumberutils],
    props: {
        account: {
            type: Object,
            default: null,
        },
        tenant: {
            type: Object,
            default: null,
        },
        list: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isMobileTel: true,
            isValidMobileTel: true,
        };
    },
    beforeUpdate() {
        if (this.account) {
            this.isMobileTel = phonenumberutils.methods.isMobile(this.account.tel);
            this.isValidMobileTel = phonenumberutils.methods.isValidNumber(this.account.tel);
        }
    },
};
</script>

<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
.fade-enter,
.fade-leave-active {
    opacity: 0;
    will-change: opacity;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
.vue-btn {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    position: relative;
    vertical-align: top;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    border: 1px solid #dbdbdb;
    color: #363636;
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0.75em;
    padding-right: 0.75em;
    text-align: center;
    white-space: nowrap;
    transition: 0.3s all ease;
}
button.vue-btn-loader-error:not(.is-loading) {
    width: 48px;
    background-color: #f44336;
    color: #fff;
}
button.vue-btn-loader-success:not(.is-loading) {
    width: 48px;
    background-color: #8bc34a;
    color: #fff;
}
button.vue-btn:disabled {
    cursor: not-allowed;
}
/**
          Spinner Icon
      **/
.spinner {
    font-size: 1px;
    display: inline-block;
    text-indent: -9999em;
    width: 14em;
    height: 14em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.spinner:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.spinner:after {
    background: #007bff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/**
          Check Icon
      **/
.check {
    display: inline-block;
    width: 23px;
    height: 24px;
    border-radius: 50%;
    transform: rotate(45deg);
    color: white;
    will-change: transform;
}
.check:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #fff;
    left: 11px;
    top: 6px;
}
.check:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #fff;
    left: 8px;
    top: 12px;
}
/**
          Cross Icon
      **/
.cross {
    display: inline-block;
    width: 17px;
    height: 16px;
    position: relative;
}
.cross:before,
.cross:after {
    position: absolute;
    left: 8px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #fff;
}
.cross:before {
    transform: rotate(45deg);
    will-change: transform;
}
.cross:after {
    transform: rotate(-45deg);
    will-change: transform;
}
</style>
