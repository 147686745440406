<template>
    <form name="form" role="form" @submit.prevent="save">
        <div class="form-group">
            <label class="control-label" for="password">{{ $t('form.password') }}</label>
            <input
                id="password"
                v-model.trim="pwd.password"
                type="password"
                class="form-control"
                name="password"
                :placeholder="$t('form.password_placeholder')"
                autocomplete="new-password"
                @input="setPassword($event.target.value)"
            />
            <validation :validation="$v.pwd.password"></validation>
            <password v-model="pwd.password" :strength-meter-only="true" />
        </div>
        <div class="form-group">
            <label class="control-label" for="confirmPassword">{{ $t('form.new_password') }}</label>
            <input
                id="confirmPassword"
                v-model.trim="pwd.confirmPassword"
                type="password"
                class="form-control"
                name="confirmPassword"
                :placeholder="$t('form.new_password_placeholder')"
                autocomplete="new-confirmpassword"
                @input="setConfirmPassword($event.target.value)"
            />
            <validation :validation="$v.pwd.confirmPassword"></validation>
        </div>
        <button type="submit" :disabled="$v.$invalid" class="btn btn-primary">{{ $t('form.button') }}</button>
    </form>
</template>

<script>
import Password from 'vue-password-strength-meter';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
    name: 'Passwordcomp',
    components: { Password },
    computed: {
        ...mapGetters({
            meta: 'account/meta',
            pwd: 'account/pwd',
        }),
    },
    methods: {
        setPassword(value) {
            this.pwd.password = value;
            this.$v.pwd.password.$touch();
            this.meta.notEqual = false;
        },
        setConfirmPassword(value) {
            this.pwd.confirmPassword = value;
            this.$v.pwd.confirmPassword.$touch();
            this.meta.notEqual = false;
        },
        save() {
            this.meta.notEqual = false;
            if (this.pwd.password !== this.pwd.confirmPassword) {
                this.meta.notEqual = true;
            } else {
                this.$emit('save', this.pwd.password);
            }
        },
    },
    validations: {
        pwd: {
            password: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(50),
            },
            confirmPassword: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(50),
            },
        },
    },
    mounted() {
        this.pwd.password = null;
        this.pwd.confirmPassword = null;
    },
};
</script>

<style scoped></style>

<!--language=json -->
<i18n>
    {
        "de": {
            "form": {
                "password": "Neues Passwort",
                "password_placeholder": "Neues Passwort",
                "new_password": "Neues Passwort bestätigen",
                "new_password_placeholder": "Bestätigen Sie Ihr neues Passwort",
                "button": "Passwort ändern"
            },
            "messages": {
                "validate": {
                    "password": {
                        "required": "Ihr neues Passwort wird benötigt.",
                        "minLength": "Ihr neues Passwort muss mind. 5 Zeichen lang sein.",
                        "maxLength": "Ihr neues Passwort darf max. 50 Zeichen haben."
                    },
                    "confirmPassword": {
                        "required": "Ihr neues Passwort muss noch bestätigt werden.",
                        "minLength": "Ihr neues Passwort muss mind. 5 Zeichen lang sein.",
                        "maxLength": "Ihr neues Passwort darf max. 50 Zeichen haben."
                    }
                }
            }
        },
        "en": {
            "form": {
                "password": "New Password",
                "password_placeholder": "New Password",
                "new_password": "Confirm new password",
                "new_password_placeholder": "Confirm new password",
                "button": "Change password"
            },
            "messages": {
                "validate": {
                    "password": {
                        "required": "Your new password name is required.",
                        "minlength": "Your new password is required to be at least 5 character.",
                        "maxlength": "Your new password cannot be longer than 50 characters."
                    },
                    "confirmPassword": {
                        "required": "Your new password name is required.",
                        "minlength": "Your new password is required to be at least 5 character.",
                        "maxlength": "Your new password cannot be longer than 50 characters."
                    }
                }
            }
        }
    }
</i18n>
